var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto" },
    [
      _c(
        "v-card-title",
        [
          _vm.showViewAsCustomer
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "6", sm: "6", md: "4", lg: "4" }
                    },
                    [_c("ViewAsCustomer")],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-expansion-panels",
            { attrs: { value: [0, 1, 2, 3, 4], multiple: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", { staticClass: "text-h6" }, [
                    _vm._v("\n          Account Summary\n        ")
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.accountLoading
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "article@3" }
                          })
                        : _c(
                            "v-container",
                            {
                              staticClass: "pa-0",
                              attrs: { fluid: "", grid: "" }
                            },
                            [
                              _c(
                                "v-banner",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    slot: "icon",
                                                    color: "accent",
                                                    size: "40"
                                                  },
                                                  slot: "icon"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon:
                                                            "mdi-shield-account",
                                                          color: "white"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-shield-account\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Your Account Information")
                                      ])
                                    ]
                                  ),
                                  _vm.account.accountName
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Account Name",
                                          value: _vm.account.accountName,
                                          url: _vm.account.website
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.nowwCustomerID
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "NOWW Id",
                                          value: _vm.account.nowwCustomerID
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.mainPhone
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Main Phone",
                                          value: _vm._f("phone")(
                                            _vm.account.mainPhone
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.otherPhone
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Other Phone",
                                          value: _vm._f("phone")(
                                            _vm.account.otherPhone
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.fax
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Fax",
                                          value: _vm._f("phone")(
                                            _vm.account.fax
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.email
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Email",
                                          value: _vm.account.email
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.addressLine1
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Address",
                                          value:
                                            (_vm.account.addressLine1 || "") +
                                            " " +
                                            (_vm.account.addressLine2 || "") +
                                            " " +
                                            (_vm.account.addressLine3 || "")
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.city
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "City",
                                          value:
                                            (_vm.account.city || "") +
                                            ", " +
                                            (_vm.account.state || "") +
                                            " " +
                                            (_vm.account.zip || "")
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-banner",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    slot: "icon",
                                                    color: "accent",
                                                    size: "40"
                                                  },
                                                  slot: "icon"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon:
                                                            "mdi-account-star",
                                                          color: "white"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-account-star\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Customer Primary Contact")
                                      ])
                                    ]
                                  ),
                                  _vm.account.primaryContact
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Primary Contact",
                                          value: _vm.account.primaryContact
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.primaryContactEmail
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Primary Contact Email",
                                          value: _vm.account.primaryContactEmail
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.primaryContactPhone
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Primary Contact Phone",
                                          value: _vm.account.primaryContactPhone
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-banner",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    slot: "icon",
                                                    color: "accent",
                                                    size: "40"
                                                  },
                                                  slot: "icon"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon:
                                                            "mdi-human-greeting-proximity",
                                                          color: "white"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-human-greeting-proximity\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("MMS Sales Rep. Contact")
                                      ])
                                    ]
                                  ),
                                  _vm.account.owner
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Sales Rep.",
                                          value: _vm.account.owner
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.ownerEmail
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Sales Rep. Email",
                                          value: _vm.account.ownerEmail
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.ownerPhone
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Sales Rep. Phone",
                                          value: _vm.account.ownerPhone
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-banner",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    slot: "icon",
                                                    color: "accent",
                                                    size: "40"
                                                  },
                                                  slot: "icon"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon:
                                                            "mdi-face-agent",
                                                          color: "white"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-face-agent\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "MMS Customer Success Specialist Contact"
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm.account.css
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label: "Customer Success Specialist",
                                          value: _vm.account.css
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.cssEmail
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label:
                                            "Customer Success Specialist Email",
                                          value: _vm.account.cssEmail
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.account.cssPhone
                                    ? _c("LabelValue", {
                                        attrs: {
                                          label:
                                            "Customer Success Specialist Phone",
                                          value: _vm.account.cssPhone
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.$store.getters["isSimulatedMMS"]()
                ? _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { staticClass: "text-h6" },
                        [_vm._v("\n          Contract History\n        ")]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.contractsLoading
                            ? _c("v-skeleton-loader", {
                                attrs: { type: "table" }
                              })
                            : _c("v-data-table", {
                                attrs: {
                                  "item-key": "id",
                                  "item-class": _vm.contractRowColor,
                                  headers: _vm.contractHeaders,
                                  items: _vm.contracts,
                                  expanded: _vm.contractsExpanded,
                                  "sort-by": ["contractEndDate"],
                                  "sort-desc": ""
                                },
                                on: { "click:row": _vm.toggleExpandRow },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "expanded-item",
                                      fn: function(ref) {
                                        var headers = ref.headers
                                        var item = ref.item
                                        return [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "px-0",
                                              attrs: { colspan: headers.length }
                                            },
                                            [
                                              _c(
                                                "v-simple-table",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c("thead", [
                                                    _c("tr", [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Product\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Quantity\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Commitment\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        File Type\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Price\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      item.crmContract
                                                        .contractLines,
                                                      function(crmLine) {
                                                        return _c(
                                                          "tr",
                                                          { key: crmLine.id },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  crmLine.productName
                                                                )
                                                              )
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  crmLine.qty
                                                                )
                                                              )
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  crmLine.commitment
                                                                )
                                                              )
                                                            ]),
                                                            _c(
                                                              "td",
                                                              [
                                                                crmLine.fileTypeLabel
                                                                  ? _c(
                                                                      "v-chip",
                                                                      {
                                                                        attrs: {
                                                                          color: _vm.$_dashMixin_fileTypeColor(
                                                                            crmLine.fileTypeLabel
                                                                          ),
                                                                          dark: _vm.$_dashMixin_fileTypeColorDark(
                                                                            crmLine.fileTypeLabel
                                                                          )
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              crmLine.fileTypeLabel
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            ),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "money"
                                                                  )(
                                                                    crmLine.chargeRate
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    crmLine.chargeType
                                                                  )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.contractStartDate",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("displayDateFormat")(
                                                  item.contractStartDate
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.contractEndDate",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("displayDateFormat")(
                                                  item.contractEndDate
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.contractFileTypes",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return _vm._l(
                                          item.contractFileTypes,
                                          function(ft) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: ft,
                                                attrs: {
                                                  color: _vm.$_dashMixin_fileTypeColor(
                                                    ft
                                                  ),
                                                  dark: _vm.$_dashMixin_fileTypeColorDark(
                                                    ft
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(ft) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          }
                                        )
                                      }
                                    },
                                    {
                                      key: "item.addOns",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(item.addOns.length) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm.hasMMSTenantManagement &&
                                          !_vm.hasContractEnded(item)
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "success"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openContractAddOnDialog(
                                                                        item,
                                                                        true
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                    mdi-pencil\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Edit Add-Ons")
                                                  ])
                                                ]
                                              )
                                            : _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "success"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openContractAddOnDialog(
                                                                        item,
                                                                        false
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                    mdi-eye\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("View Add-Ons")
                                                  ])
                                                ]
                                              ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.toggleExpandedContract(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                    mdi-chevron-" +
                                                                _vm._s(
                                                                  item._expandedIcon
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("See details")
                                              ])
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3406829783
                                )
                              }),
                          _c(
                            "v-dialog",
                            {
                              attrs: { persistent: "" },
                              model: {
                                value: _vm.contractAddOnMenu,
                                callback: function($$v) {
                                  _vm.contractAddOnMenu = $$v
                                },
                                expression: "contractAddOnMenu"
                              }
                            },
                            [
                              _vm.selectedContract
                                ? _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-h6 grey lighten-2",
                                          attrs: { "primary-title": "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.selectedContract
                                                  .contractNumber
                                              ) +
                                              " Add-Ons\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass: "black--text pt-5 pb-0"
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        color:
                                                          _vm.contractAddOnAddedColor
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Contract Add-On\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        color:
                                                          _vm.contractAddOnBaseColor
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.selectedContract
                                                              .packageName
                                                          ) +
                                                          " Included\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        color:
                                                          _vm.contractAddOnMissingColor
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.selectedContract
                                                              .packageName
                                                          ) +
                                                          " Not Included\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  !_vm.contractAddOnLoading
                                                    ? _c("v-simple-table", {
                                                        attrs: { dense: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function() {
                                                                return [
                                                                  _c("thead", [
                                                                    _c("tr", [
                                                                      _c(
                                                                        "th",
                                                                        {
                                                                          staticClass:
                                                                            "text-h6 text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Category\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "th",
                                                                        {
                                                                          staticClass:
                                                                            "text-h6 text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Features\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ])
                                                                  ]),
                                                                  _c(
                                                                    "tbody",
                                                                    _vm._l(
                                                                      _vm.displayedCategories,
                                                                      function(
                                                                        category
                                                                      ) {
                                                                        return _c(
                                                                          "tr",
                                                                          {
                                                                            key:
                                                                              category.code
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-chip",
                                                                                  {
                                                                                    staticClass:
                                                                                      "black--text",
                                                                                    attrs: {
                                                                                      color:
                                                                                        category.color
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          category.title
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              _vm._l(
                                                                                category.features,
                                                                                function(
                                                                                  feature
                                                                                ) {
                                                                                  return _c(
                                                                                    "v-chip",
                                                                                    {
                                                                                      key:
                                                                                        feature.code,
                                                                                      staticClass:
                                                                                        "ma-1 black--text",
                                                                                      attrs: {
                                                                                        disabled:
                                                                                          _vm.contractAddOnSaveLoading,
                                                                                        color: _vm.contractHasClaim(
                                                                                          category.code,
                                                                                          feature.code
                                                                                        )
                                                                                          ? _vm.contractAddOnBaseColor
                                                                                          : _vm.contractHasClaimAddOn(
                                                                                              category.code,
                                                                                              feature.code
                                                                                            )
                                                                                          ? _vm.contractAddOnAddedColor
                                                                                          : _vm.contractAddOnMissingColor
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          !_vm.contractHasClaim(
                                                                                            category.code,
                                                                                            feature.code
                                                                                          ) &&
                                                                                          _vm.contractAddOnEditable
                                                                                            ? _vm.toggleAddOn(
                                                                                                category.code,
                                                                                                feature.code
                                                                                              )
                                                                                            : _vm.doNothing()
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            feature.title
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          3206346758
                                                        )
                                                      })
                                                    : _c("v-skeleton-loader", {
                                                        attrs: {
                                                          type: "article@3"
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled:
                                                  _vm.contractAddOnSaveLoading
                                              },
                                              on: {
                                                click:
                                                  _vm.closeContractAddOnDialog
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.contractAddOnEditable
                                                      ? "Cancel"
                                                      : "Close"
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _vm.contractAddOnEditable
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    loading:
                                                      _vm.contractAddOnSaveLoading
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.saveContractAddOns
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Save\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-expansion-panel",
                { attrs: { readonly: "" } },
                [
                  _c("v-expansion-panel-header", { staticClass: "text-h6" }, [
                    _vm._v(
                      "\n          Commitment Usage Summary - " +
                        _vm._s(
                          _vm.contractUsage &&
                            !_vm.contractUsage.errorResponse &&
                            _vm.contractUsage.contract
                            ? _vm.contractUsage.contract.contractNumber
                            : ""
                        ) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-container",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.contractUsage &&
                                _vm.contractUsage.chartData &&
                                _vm.contractUsage.chartData.datasets.length > 0,
                              expression:
                                "contractUsage && contractUsage.chartData && contractUsage.chartData.datasets.length > 0"
                            }
                          ],
                          staticClass: "pa-0",
                          attrs: { fluid: "", grid: "" }
                        },
                        [
                          _c("bar-chart", {
                            attrs: {
                              "chart-data": _vm.chartDataComputed,
                              options: _vm.usageChartOptions
                            }
                          })
                        ],
                        1
                      ),
                      _vm.contractUsageLoading ||
                      _vm.contractsLoading ||
                      !_vm.contractUsage
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "article@3" }
                          })
                        : _vm.contractUsage.errorResponse
                        ? _c("v-container", [
                            _c("span", [_vm._v("Error getting usage")])
                          ])
                        : _vm.contractUsage &&
                          _vm.contractUsage.chartData &&
                          _vm.contractUsage.chartData.datasets.length <= 0
                        ? _c("v-container", [_c("span", [_vm._v("No Usage")])])
                        : _vm._e(),
                      _c("v-divider"),
                      _vm.contractInvoicesLoading ||
                      _vm.contractInvoices === null
                        ? _c("v-skeleton-loader", { attrs: { type: "table" } })
                        : _c(
                            "v-container",
                            [
                              _c("span", { staticClass: "text-h6" }, [
                                _vm._v("Contract Invoices")
                              ]),
                              _c("br"),
                              _vm.contractInvoices.length === 0
                                ? _c("span", [_vm._v("No Invoices")])
                                : _c("v-simple-table", {
                                    attrs: { dense: "", "fixed-header": "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function() {
                                          return [
                                            _c("thead", [
                                              _c(
                                                "tr",
                                                _vm._l(
                                                  _vm.invoiceHeaders,
                                                  function(
                                                    invoiceHeader,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "th",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "text-left",
                                                        attrs: {
                                                          width:
                                                            invoiceHeader.width
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              invoiceHeader.text
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.contractInvoices,
                                                function(contractInvoice) {
                                                  return _c(
                                                    "tr",
                                                    { key: contractInvoice.id },
                                                    _vm._l(
                                                      _vm.invoiceHeaders,
                                                      function(
                                                        invoiceHeader,
                                                        i
                                                      ) {
                                                        return _c(
                                                          "td",
                                                          {
                                                            key:
                                                              contractInvoice.id +
                                                              "-" +
                                                              i,
                                                            attrs: {
                                                              width:
                                                                invoiceHeader.width
                                                            }
                                                          },
                                                          [
                                                            invoiceHeader.value ===
                                                            "createdOn"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "displayDateTimeFormat"
                                                                        )(
                                                                          contractInvoice[
                                                                            invoiceHeader
                                                                              .value
                                                                          ]
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ])
                                                              : invoiceHeader.value ===
                                                                "totalAmount"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "money"
                                                                        )(
                                                                          contractInvoice[
                                                                            invoiceHeader
                                                                              .value
                                                                          ]
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ])
                                                              : invoiceHeader.value ===
                                                                "actions"
                                                              ? _c(
                                                                  "span",
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-2",
                                                                                        attrs: {
                                                                                          color:
                                                                                            "success"
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.downloadInvoicePDF(
                                                                                              contractInvoice.id
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              mdi-file-download\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Download PDF"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        contractInvoice[
                                                                          invoiceHeader
                                                                            .value
                                                                        ]
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ])
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  })
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.hasAdminRole || _vm.hasOwnerRole
                ? _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { staticClass: "text-h6" },
                        [_vm._v("\n          Campaign Settings\n        ")]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.accountLoading
                            ? _c("v-skeleton-loader", {
                                attrs: { type: "article" }
                              })
                            : _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "10",
                                        sm: "8",
                                        md: "6",
                                        lg: "4"
                                      }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          "item-text": "email",
                                          "item-value": "id",
                                          items: _vm.customerEmails,
                                          multiple: "",
                                          dense: "",
                                          "hide-selected": "",
                                          "deletable-chips": "",
                                          "error-messages":
                                            _vm.customerEmailErrors,
                                          "search-input":
                                            _vm.customerEmailSearch,
                                          loading: _vm.tenantContactsLoading,
                                          label:
                                            "Select Test Contacts or Add New",
                                          color: "primary",
                                          counter: "",
                                          "small-chips": "",
                                          "auto-select-first": "",
                                          "hide-no-data": ""
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.customerEmailSearch = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.customerEmailSearch = $event
                                          },
                                          change: function($event) {
                                            _vm.customerEmailSearch = null
                                            _vm.customerEmailErrors = null
                                          },
                                          keyup: [
                                            function($event) {
                                              return _vm.checkIfEmailValid()
                                            },
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.appendNewTestEmail(
                                                _vm.customerEmailSearch
                                              )
                                            }
                                          ]
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                item.email
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                          item.firstName ||
                                                          item.lastName
                                                            ? _c(
                                                                "v-list-item-subtitle",
                                                                {
                                                                  staticClass:
                                                                    "ml-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        (" +
                                                                      _vm._s(
                                                                        (
                                                                          (item.firstName ||
                                                                            "") +
                                                                          " " +
                                                                          (item.lastName ||
                                                                            "")
                                                                        ).trim()
                                                                      ) +
                                                                      ")\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2154451801
                                        ),
                                        model: {
                                          value: _vm.selectedEmails,
                                          callback: function($$v) {
                                            _vm.selectedEmails = $$v
                                          },
                                          expression: "selectedEmails"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex align-center",
                                      attrs: { md: "1", cols: "2" }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onTooltip = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass: "mx-1",
                                                          attrs: {
                                                            icon: "",
                                                            color: "error",
                                                            disabled:
                                                              _vm.selectedEmails
                                                                .length < 1 ||
                                                              _vm.tenantContactsLoading
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.deleteTestContacts
                                                          }
                                                        },
                                                        Object.assign(
                                                          {},
                                                          onTooltip
                                                        )
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "\n                      mdi-account" +
                                                              _vm._s(
                                                                _vm
                                                                  .selectedEmails
                                                                  .length < 2
                                                                  ? ""
                                                                  : "-multiple"
                                                              ) +
                                                              "-remove\n                    "
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            763117340
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Delete Test Contact" +
                                                _vm._s(
                                                  _vm.selectedEmails.length < 2
                                                    ? ""
                                                    : "s"
                                                )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "10",
                                        sm: "8",
                                        md: "6",
                                        lg: "4"
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "Default Parse Text Version of Creative"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeDefaultTextVersionCreative(
                                              _vm.tenant.id
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.tenant
                                              .optInDefaultTextVersionCreative,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tenant,
                                              "optInDefaultTextVersionCreative",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tenant.optInDefaultTextVersionCreative"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$store.getters["auth/isMMSRole"]()
                ? _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { staticClass: "text-h6" },
                        [_vm._v("\n          Sending Domain\n        ")]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "10",
                                    sm: "8",
                                    md: "6",
                                    lg: "4"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled:
                                        !_vm.hasMMSTenantManagement ||
                                        _vm.acousticLoginId !== null,
                                      items: _vm.acousticLoginsSelect,
                                      loading: _vm.acousticLoginsLoading,
                                      dense: "",
                                      color: "primary",
                                      "item-color": "primary",
                                      label: "Sending Domains",
                                      placeholder: "(Pod) Username"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.saveAcousticLoginId()
                                      }
                                    },
                                    model: {
                                      value: _vm.acousticLoginId,
                                      callback: function($$v) {
                                        _vm.acousticLoginId = $$v
                                      },
                                      expression: "acousticLoginId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }