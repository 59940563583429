<template>
  <v-card class="mx-auto">
    <v-card-title>
      <v-row
        v-if="showViewAsCustomer"
        align="center"
      >
        <v-col
          class="py-0"
          cols="6"
          sm="6"
          md="4"
          lg="4"
        >
          <ViewAsCustomer />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels
        :value="[0,1,2,3,4]"
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6">
            Account Summary
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader
              v-if="accountLoading"
              type="article@3"
            />
            <v-container
              v-else
              fluid
              grid
              class="pa-0"
            >
              <v-banner>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-avatar
                      slot="icon"
                      color="accent"
                      size="40"
                    >
                      <v-icon
                        icon="mdi-shield-account"
                        color="white"
                        v-on="on"
                      >
                        mdi-shield-account
                      </v-icon>
                    </v-avatar>
                  </template>
                  <span>Your Account Information</span>
                </v-tooltip>
                <LabelValue
                  v-if="account.accountName"
                  label="Account Name"
                  :value="account.accountName"
                  :url="account.website"
                />
                <LabelValue
                  v-if="account.nowwCustomerID"
                  label="NOWW Id"
                  :value="account.nowwCustomerID"
                />
                <LabelValue
                  v-if="account.mainPhone"
                  label="Main Phone"
                  :value="account.mainPhone | phone"
                />
                <LabelValue
                  v-if="account.otherPhone"
                  label="Other Phone"
                  :value="account.otherPhone | phone"
                />
                <LabelValue
                  v-if="account.fax"
                  label="Fax"
                  :value="account.fax | phone"
                />
                <LabelValue
                  v-if="account.email"
                  label="Email"
                  :value="account.email"
                />
                <LabelValue
                  v-if="account.addressLine1"
                  label="Address"
                  :value="`${account.addressLine1 || ''} ${account.addressLine2 || ''} ${account.addressLine3 || ''}`"
                />
                <LabelValue
                  v-if="account.city"
                  label="City"
                  :value="`${account.city || ''}, ${account.state || ''} ${account.zip || ''}`"
                />
              </v-banner>

              <v-banner>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-avatar
                      slot="icon"
                      color="accent"
                      size="40"
                    >
                      <v-icon
                        icon="mdi-account-star"
                        color="white"
                        v-on="on"
                      >
                        mdi-account-star
                      </v-icon>
                    </v-avatar>
                  </template>
                  <span>Customer Primary Contact</span>
                </v-tooltip>

                <LabelValue
                  v-if="account.primaryContact"
                  label="Primary Contact"
                  :value="account.primaryContact"
                />
                <LabelValue
                  v-if="account.primaryContactEmail"
                  label="Primary Contact Email"
                  :value="account.primaryContactEmail"
                />
                <LabelValue
                  v-if="account.primaryContactPhone"
                  label="Primary Contact Phone"
                  :value="account.primaryContactPhone"
                />
              </v-banner>

              <v-banner>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-avatar
                      slot="icon"
                      color="accent"
                      size="40"
                    >
                      <v-icon
                        icon="mdi-human-greeting-proximity"
                        color="white"
                        v-on="on"
                      >
                        mdi-human-greeting-proximity
                      </v-icon>
                    </v-avatar>
                  </template>
                  <span>MMS Sales Rep. Contact</span>
                </v-tooltip>

                <LabelValue
                  v-if="account.owner"
                  label="Sales Rep."
                  :value="account.owner"
                />
                <LabelValue
                  v-if="account.ownerEmail"
                  label="Sales Rep. Email"
                  :value="account.ownerEmail"
                />
                <LabelValue
                  v-if="account.ownerPhone"
                  label="Sales Rep. Phone"
                  :value="account.ownerPhone"
                />
              </v-banner>

              <v-banner>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-avatar
                      slot="icon"
                      color="accent"
                      size="40"
                    >
                      <v-icon
                        icon="mdi-face-agent"
                        color="white"
                        v-on="on"
                      >
                        mdi-face-agent
                      </v-icon>
                    </v-avatar>
                  </template>
                  <span>MMS Customer Success Specialist Contact</span>
                </v-tooltip>

                <LabelValue
                  v-if="account.css"
                  label="Customer Success Specialist"
                  :value="account.css"
                />
                <LabelValue
                  v-if="account.cssEmail"
                  label="Customer Success Specialist Email"
                  :value="account.cssEmail"
                />
                <LabelValue
                  v-if="account.cssPhone"
                  label="Customer Success Specialist Phone"
                  :value="account.cssPhone"
                />
              </v-banner>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="!$store.getters['isSimulatedMMS']()">
          <v-expansion-panel-header class="text-h6">
            Contract History
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader
              v-if="contractsLoading"
              type="table"
            />
            <v-data-table
              v-else
              item-key="id"
              :item-class="contractRowColor"
              :headers="contractHeaders"
              :items="contracts"
              :expanded="contractsExpanded"
              :sort-by="['contractEndDate']"
              sort-desc
              @click:row="toggleExpandRow"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="px-0"
                >
                  <v-simple-table
                    dense
                  >
                    <thead>
                      <tr>
                        <th class="text-left">
                          Product
                        </th>
                        <th class="text-left">
                          Quantity
                        </th>
                        <th class="text-left">
                          Commitment
                        </th>
                        <th class="text-left">
                          File Type
                        </th>
                        <th class="text-left">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="crmLine in item.crmContract.contractLines"
                        :key="crmLine.id"
                      >
                        <td>{{ crmLine.productName }}</td>
                        <td>{{ crmLine.qty }}</td>
                        <td>{{ crmLine.commitment }}</td>
                        <td>
                          <v-chip
                            v-if="crmLine.fileTypeLabel"
                            :color="$_dashMixin_fileTypeColor(crmLine.fileTypeLabel)"
                            :dark="$_dashMixin_fileTypeColorDark(crmLine.fileTypeLabel)"
                          >
                            {{ crmLine.fileTypeLabel }}
                          </v-chip>
                        </td>
                        <td>{{ crmLine.chargeRate | money }} {{ crmLine.chargeType }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
              <template v-slot:item.contractStartDate="{ item }">
                {{ item.contractStartDate | displayDateFormat }}
              </template>
              <template v-slot:item.contractEndDate="{ item }">
                {{ item.contractEndDate | displayDateFormat }}
              </template>
              <template v-slot:item.contractFileTypes="{ item }">
                <!-- {{ item.contractFileTypes.join(", ") }} -->
                <v-chip
                  v-for="ft in item.contractFileTypes"
                  :key="ft"
                  :color="$_dashMixin_fileTypeColor(ft)"
                  :dark="$_dashMixin_fileTypeColorDark(ft)"
                >
                  {{ ft }}
                </v-chip>
              </template>
              <template v-slot:item.addOns="{ item }">
                {{ item.addOns.length }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip
                  v-if="hasMMSTenantManagement && !hasContractEnded(item)"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      color="success"
                      v-on="on"
                      @click="openContractAddOnDialog(item, true)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit Add-Ons</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      color="success"
                      v-on="on"
                      @click="openContractAddOnDialog(item, false)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>View Add-Ons</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="cursor: pointer;"
                      v-on="on"
                      @click="toggleExpandedContract(item)"
                    >
                      mdi-chevron-{{ item._expandedIcon }}
                    </v-icon>
                  </template>
                  <span>See details</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-dialog
              v-model="contractAddOnMenu"
              persistent
            >
              <v-card v-if="selectedContract">
                <v-card-title
                  class="text-h6 grey lighten-2"
                  primary-title
                >
                  {{ selectedContract.contractNumber }} Add-Ons
                </v-card-title>
                <v-card-text
                  class="black--text pt-5 pb-0"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-chip
                        :color="contractAddOnAddedColor"
                        class="mr-2"
                      >
                        Contract Add-On
                      </v-chip>
                      <v-chip
                        :color="contractAddOnBaseColor"
                        class="mr-2"
                      >
                        {{ selectedContract.packageName }} Included
                      </v-chip>
                      <v-chip
                        :color="contractAddOnMissingColor"
                        class="mr-2"
                      >
                        {{ selectedContract.packageName }} Not Included
                      </v-chip>
                    </v-col>
                    <v-col cols="12">
                      <v-simple-table
                        v-if="!contractAddOnLoading"
                        dense
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-h6 text-left">
                                Category
                              </th>
                              <th class="text-h6 text-left">
                                Features
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="category in displayedCategories"
                              :key="category.code"
                            >
                              <td>
                                <v-chip
                                  class="black--text"
                                  :color="category.color"
                                >
                                  {{ category.title }}
                                </v-chip>
                              </td>
                              <td>
                                <v-chip
                                  v-for="feature in category.features"
                                  :key="feature.code"
                                  class="ma-1 black--text"
                                  :disabled="contractAddOnSaveLoading"
                                  :color="contractHasClaim(category.code, feature.code) ? contractAddOnBaseColor
                                    : contractHasClaimAddOn(category.code, feature.code) ? contractAddOnAddedColor
                                      : contractAddOnMissingColor"
                                  @click="!contractHasClaim(category.code, feature.code) && contractAddOnEditable ? toggleAddOn(category.code, feature.code) : doNothing()"
                                >
                                  {{ feature.title }}
                                </v-chip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-skeleton-loader
                        v-else
                        type="article@3"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="contractAddOnSaveLoading"
                    @click="closeContractAddOnDialog"
                  >
                    {{ contractAddOnEditable ? 'Cancel' : 'Close' }}
                  </v-btn>
                  <v-btn
                    v-if="contractAddOnEditable"
                    color="success"
                    :loading="contractAddOnSaveLoading"
                    @click="saveContractAddOns"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="text-h6">
            Commitment Usage Summary - {{ contractUsage && !contractUsage.errorResponse && contractUsage.contract ? contractUsage.contract.contractNumber : '' }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container
              v-show="contractUsage && contractUsage.chartData && contractUsage.chartData.datasets.length > 0"
              fluid
              grid
              class="pa-0"
            >
              <bar-chart
                :chart-data="chartDataComputed"
                :options="usageChartOptions"
              />
            </v-container>
            <v-skeleton-loader
              v-if="contractUsageLoading || contractsLoading || !contractUsage"
              type="article@3"
            />
            <v-container
              v-else-if="contractUsage.errorResponse"
            >
              <span>Error getting usage</span>
            </v-container>
            <v-container
              v-else-if="contractUsage && contractUsage.chartData && contractUsage.chartData.datasets.length <= 0"
            >
              <span>No Usage</span>
            </v-container>
            <v-divider />
            <v-skeleton-loader
              v-if="contractInvoicesLoading || contractInvoices === null"
              type="table"
            />
            <v-container v-else>
              <span class="text-h6">Contract Invoices</span>
              <br>
              <span v-if="contractInvoices.length === 0">No Invoices</span>
              <v-simple-table
                v-else
                dense
                fixed-header
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-for="(invoiceHeader, index) in invoiceHeaders"
                        :key="index"
                        class="text-left"
                        :width="invoiceHeader.width"
                      >
                        {{ invoiceHeader.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(contractInvoice) in contractInvoices"
                      :key="contractInvoice.id"
                    >
                      <td
                        v-for="(invoiceHeader, i) in invoiceHeaders"
                        :key="`${contractInvoice.id}-${i}`"
                        :width="invoiceHeader.width"
                      >
                        <span v-if="invoiceHeader.value === 'createdOn'">
                          {{ contractInvoice[invoiceHeader.value] | displayDateTimeFormat }}
                        </span>
                        <span v-else-if="invoiceHeader.value === 'totalAmount'">
                          {{ contractInvoice[invoiceHeader.value] | money }}
                        </span>
                        <span v-else-if="invoiceHeader.value === 'actions'">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                class="mr-2"
                                color="success"
                                @click="downloadInvoicePDF(contractInvoice.id)"
                                v-on="on"
                              >
                                mdi-file-download
                              </v-icon>
                            </template>
                            <span>Download PDF</span>
                          </v-tooltip>
                        </span>
                        <span v-else>
                          {{ contractInvoice[invoiceHeader.value] }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="hasAdminRole || hasOwnerRole">
          <v-expansion-panel-header class="text-h6">
            Campaign Settings
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader
              v-if="accountLoading"
              type="article"
            />
            <v-row v-else>
              <v-col
                cols="10"
                sm="8"
                md="6"
                lg="4"
              >
                <v-autocomplete
                  v-model="selectedEmails"
                  item-text="email"
                  item-value="id"
                  :items="customerEmails"
                  multiple
                  dense
                  hide-selected
                  deletable-chips
                  :error-messages="customerEmailErrors"
                  :search-input.sync="customerEmailSearch"
                  :loading="tenantContactsLoading"
                  label="Select Test Contacts or Add New"
                  color="primary"
                  counter
                  small-chips
                  auto-select-first
                  hide-no-data
                  @change="customerEmailSearch = null; customerEmailErrors = null"
                  @keyup="checkIfEmailValid()"
                  @keyup.enter="appendNewTestEmail(customerEmailSearch)"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content class="d-flex">
                      <v-list-item-title class="d-flex">
                        {{ item.email }}
                        <v-list-item-subtitle
                          v-if="item.firstName || item.lastName"
                          class="ml-2"
                        >
                          ({{ `${item.firstName || ''} ${item.lastName || ''}`.trim() }})
                        </v-list-item-subtitle>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                md="1"
                cols="2"
                class="d-flex align-center"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      icon
                      color="error"
                      :disabled="selectedEmails.length < 1 || tenantContactsLoading"
                      class="mx-1"
                      v-on="{ ...onTooltip }"
                      @click="deleteTestContacts"
                    >
                      <v-icon>
                        mdi-account{{ selectedEmails.length &lt; 2 ? '' : '-multiple' }}-remove
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Test Contact{{ selectedEmails.length &lt; 2 ? '' : 's' }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="10"
                sm="8"
                md="6"
                lg="4"
              >
                <v-checkbox
                  v-model="tenant.optInDefaultTextVersionCreative"
                  label="Default Parse Text Version of Creative"
                  @change="changeDefaultTextVersionCreative(tenant.id)"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="$store.getters['auth/isMMSRole']()">
          <v-expansion-panel-header class="text-h6">
            Sending Domain
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                cols="10"
                sm="8"
                md="6"
                lg="4"
              >
                <v-select
                  v-model="acousticLoginId"
                  :disabled="!hasMMSTenantManagement || acousticLoginId !== null"
                  :items="acousticLoginsSelect"
                  :loading="acousticLoginsLoading"
                  dense
                  color="primary"
                  item-color="primary"
                  label="Sending Domains"
                  placeholder="(Pod) Username"
                  @change="saveAcousticLoginId()"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- <v-expansion-panel>
          <v-expansion-panel-header class="text-h6">
            Opt Out Settings
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                cols="6"
                sm="4"
                md="3"
                lg="2"
              >
                <v-select
                  v-model="optOut"
                  :items="optOutSelect"
                  :loading="optOutLoading"
                  dense
                  color="primary"
                  item-color="primary"
                  label="Opt Out"
                  class="px-2 pt-4 input-max-width"
                />
              </v-col>
              <v-col
                cols="6"
                sm="4"
                md="3"
                lg="2"
                class="d-flex align-center"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      icon
                      :color="optOutEdit ? 'info' : 'warning'"
                      :disabled="!optOut || isLoading"
                      class="mx-1"
                      v-on="{ ...onTooltip }"
                      @click="optOutEdit = !optOutEdit"
                    >
                      <v-icon>mdi-{{ optOutEdit ? 'text-box' : 'file-document-edit' }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ optOutEdit ? 'Preview Opt Out' : 'Edit Opt Out' }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      icon
                      color="error"
                      :disabled="!optOut || isLoading"
                      class="mx-1"
                      v-on="{ ...onTooltip }"
                      @click="deleteOptOut"
                    >
                      <v-icon>mdi-text-box-remove</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Opt Out</span>
                </v-tooltip>
                <v-menu
                  v-model="createOptOutMenu"
                  right
                  x-offset
                  origin="right"
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  :close-on-click="false"
                >
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-btn
                          :disabled="isLoading"
                          icon
                          color="success"
                          class="mx-1"
                          v-on="{ ...onMenu, ...onTooltip }"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Create New Opt Out</span>
                    </v-tooltip>
                  </template>

                  <v-card :width="menuWidth">
                    <v-card-text class="px-5 py-2">
                      <v-text-field
                        v-model="createOptOut"
                        :error-messages="createOptOutErrors"
                        required
                        label="Opt Out Name"
                        @input="$v.createOptOut.$touch()"
                        @blur="$v.createOptOut.$touch()"
                        @keyup.enter="createOptOutSave(createOptOut)"
                      />
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="tertiary"
                        :disabled="isLoading"
                        @click="closeCreateOptOut"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="success"
                        :loading="isLoading"
                        @click="createOptOutSave(createOptOut)"
                      >
                        Create
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>

            <OptOutPreview
              :opt-out-id="optOut"
              :edit-mode="optOutEdit"
              footer-margin-top="60"
            />
          </v-expansion-panel-content>
        </v-expansion-panel> -->
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { categoryFeatures, packages, roles } from '@/shared/models/auth'
import { downloadFileMixin } from '@/shared/mixins/general'
import mutationTypes from '@/store/mutation-types'
import { tenantService, contactService } from '@/shared/services'
import { required } from 'vuelidate/lib/validators'
import claims from '@/shared/models/auth/claims'
import { dashboardMixin } from '@/shared/mixins/mailing'

function initialState () {
  return {
    tenant: null,
    tenantLoading: false,
    account: null,
    accountLoading: false,
    contracts: null,
    contractsLoading: false,
    contractAddOnMenu: false,
    contractAddOnLoading: false,
    contractAddOnSaveLoading: false,
    contractAddOnEditable: false,
    contractAddOnBaseColor: 'green lighten-3',
    contractAddOnMissingColor: 'grey lighten-2',
    contractAddOnAddedColor: 'green',
    contractHeaders: [
      {
        text: 'Contract Number',
        value: 'contractNumber'
      },
      {
        text: 'Package',
        value: 'packageName'
      },
      {
        text: 'Start Date',
        value: 'contractStartDate'
      },
      {
        text: 'End Date',
        value: 'contractEndDate'
      },
      {
        text: 'File Types',
        value: 'contractFileTypes'
      },
      {
        text: 'Add-Ons',
        value: 'addOns'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        filterable: false
      }
    ],
    contractsExpanded: [],
    invoiceHeaders: [
      {
        text: 'Created On',
        value: 'createdOn',
        width: '200'
      },
      {
        text: 'Name',
        value: 'name',
        width: '500'
      },
      {
        text: 'Total',
        value: 'totalAmount',
        width: '200'
      },
      {
        text: 'Actions',
        value: 'actions',
        width: '100'
      }
    ],
    allCategoryFeatures: categoryFeatures,
    selectedContract: null,
    selectedContractAddOns: null,
    acousticLoginId: null,
    acousticLoginsSelect: [],
    acousticLoginsLoading: false,
    optOut: null,
    optOutSelect: [],
    optOutLoading: false,
    createOptOut: '',
    createOptOutMenu: false,
    optOutEdit: false,
    tenantContactsLoading: false,
    contractUsageLoading: false,
    contractInvoices: null,
    contractInvoicesLoading: false,
    contractUsage: {
      chartData: {
        labels: [],
        datasets: []
      }
    },
    selectedEmails: [],
    customerEmails: [],
    customerEmailSearch: null,
    customerEmailErrors: null,
    menuWidth: 500,
    usageChartOptions: {
      responsive: true,
      maintainAspectRatio: false
    }
  }
}

export default {
  name: 'CustomerAccount',

  components: {
    // OptOutPreview: () => import('@/views/pages/components/mailing/OptOutPreview'),
    ViewAsCustomer: () => import('@/views/pages/components/user-state/ViewAsCustomer'),
    LabelValue: () => import('@/components/custom/LabelValue'),
    BarChart: () => import('@/views/pages/components/charts/BarChart')
  },

  mixins: [dashboardMixin, downloadFileMixin],

  validations: {
    createOptOut: {
      required
    }
  },

  data () {
    return initialState()
  },

  computed: {
    showViewAsCustomer () {
      return this.$store.getters['auth/hasClaimKV'](claims.MMS_TEBT)
    },
    hasAdminRole () {
      return this.$store.getters['auth/getRoleTitle']() === "Admin"
    },
    hasOwnerRole () {
      return this.$store.getters['auth/getRoleTitle']() === "Owner"
    },
    hasMMSTenantManagement () {
      return this.$store.getters['auth/hasClaimKV'](claims.MMS_TM)
    },
    createOptOutErrors () {
      const errors = []
      if (!this.$v.createOptOut.$dirty) return errors
      !this.$v.createOptOut.required && errors.push('Opt Out Name is required')
      return errors
    },
    isEmailValid () {
      return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.customerEmailSearch)
    },
    displayedCategories () {
      return this.allCategoryFeatures.filter(x => x.code !== 'MMS')
    },
    chartDataComputed () {
      return this.contractUsage && this.contractUsage.chartData ? this.contractUsage.chartData : {}
    }
  },

  created () {
    this.init()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(async mutation => {
      let listeners = [mutationTypes.SET_VIEW_AS_CUSTOMER]
      if (listeners.includes(mutation.type)) {
        this.init()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    init () {
      Object.assign(this.$data, initialState())
      this.loadTenantDetails()
      this.loadTenantContacts()
      this.loadPlatformContracts()
      // this.loadOptOuts()
      this.loadAcousticLogins()
      this.loadCrmAccountDetails()
    },
    async loadTenantDetails () {
      this.tenantLoading = true
      var resp = await tenantService.getTenantByCrmId(this.$store.getters['simulatedCustomerCrmId']())
      this.tenant = resp
      this.acousticLoginId = this.tenant.acousticLoginId
      this.tenantLoading = false
    },
    async loadCrmAccountDetails () {
      this.accountLoading = true
      var resp = await tenantService.getAccountByCrmId(this.$store.getters['simulatedCustomerCrmId']())
      this.account = resp
      this.accountLoading = false
    },
    async loadPlatformContracts () {
      if (this.$store.getters['isSimulatedMMS']()) {
        this.contracts = null
        this.contractInvoices = null
      } else {
        this.contractsLoading = true
        this.contracts = null
        var resp = await tenantService.getPlatformContracts(this.$store.getters['simulatedCustomerCrmId']())
        this.contracts = resp.map(x => (
          {
            ...x,
            _expandedIcon: 'down'
          }
        ))
        this.contractsLoading = false
        if (this.$store.getters['auth/hasClaimKV'](claims.AD_BH) && this.contracts.length > 0) {
          this.loadPlatformContractUsage(this.contracts.find(x => x.isActive === true).id)
          this.loadContractInvoices(this.contracts.find(x => x.isActive === true).contractCrmId)
        }
      }
    },
    async loadContractInvoices (contractId) {
      this.contractInvoicesLoading = true
      var resp = await tenantService.getInvoices(contractId)
      Vue.set(this, 'contractInvoices', resp)
      this.contractInvoicesLoading = false
    },
    async loadPlatformContractUsage (id) {
      this.contractUsageLoading = true
      var resp = await tenantService.getPlatformContractUsage(id)
      this.contractUsage.chartData = {
        labels: [],
        datasets: []
      }
      this.contractUsage.chartData.labels = resp.chartData.labels
      this.contractUsage.chartData.datasets = resp.chartData.datasets
      Vue.set(this.contractUsage, 'contract', resp.contract)
      this.contractUsageLoading = false
    },
    async loadAcousticLogins () {
      this.acousticLoginsLoading = true
      var resp = await tenantService.getAcousticLoginsList()
      this.acousticLoginsSelect = resp.map(x => ({
        ...x,
        value: parseInt(x.value)
      }))
      this.acousticLoginsLoading = false
    },
    async loadOptOuts () {
      this.optOutLoading = true
      var resp = await tenantService.getOptOutList(this.$store.getters['simulatedCustomerCrmId']())
      this.optOutSelect = resp
      this.optOutLoading = false
    },
    async downloadInvoicePDF (id) {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading')
      await tenantService.getInvoicePDF(id)
        .then(function (resp) {
          _this.$_downloadFileMixin_downloadResponse(resp)
        })
    },
    async createOptOutSave (name) {
      this.$v.createOptOut.$touch()
      if (this.$v.createOptOut.$invalid) {
        return
      }
      var id = await tenantService.createOptOut({
        tenantCrmId: this.$store.getters['simulatedCustomerCrmId'](),
        displayName: name
      })
      await this.loadOptOuts()
      this.optOut = id
      this.closeCreateOptOut()
    },
    closeCreateOptOut () {
      this.createOptOutMenu = false
      this.createOptOut = ''
    },
    async deleteOptOut () {
      await tenantService.deleteOptOut(this.optOut)
      await this.loadOptOuts()
      this.optOut = null
    },
    async loadTenantContacts () {
      this.tenantContactsLoading = true
      this.customerEmails = await contactService.getTenantContacts(this.$store.getters['simulatedCustomerCrmId']())
      this.tenantContactsLoading = false
    },
    checkIfEmailValid () {
      if (this.isEmailValid) { this.customerEmailErrors = null }
    },
    async appendNewTestEmail () {
      // if it is already on the list of selectable contacts and user is using typing to select => return
      if (!this.customerEmailSearch || this.customerEmails.some(x => x.email.toLowerCase().includes(this.customerEmailSearch.toLowerCase()))) {
        this.customerEmailErrors = null
        return
      }
      // if email invalid => return
      if (!this.isEmailValid) {
        this.customerEmailErrors = 'Must be a valid email'
        return
      }

      let contact = {
        tenantCrmId: this.$store.getters['simulatedCustomerCrmId'](),
        email: this.customerEmailSearch.toLowerCase()
      }
      this.tenantContactsLoading = true
      var contactId = await contactService.createContact(contact)
      this.tenantContactsLoading = false

      contact.id = contactId

      this.customerEmails.push(contact)
      this.selectedEmails.push(contact.id)
      this.customerEmailSearch = null
      this.customerEmailErrors = null
    },
    async deleteTestContacts () {
      this.tenantContactsLoading = true
      await contactService.deleteContacts(this.selectedEmails)
      this.tenantContactsLoading = false
      this.selectedEmails = []
      await this.loadTenantContacts()
    },
    async saveAcousticLoginId () {
      var request = {
        id: this.tenant.id,
        acousticLoginId: this.acousticLoginId
      }
      await tenantService.assignAcousticLogin(request)
    },
    hasContractEnded (contract) {
      return contract.contractEndDate < moment().format()
    },
    openContractAddOnDialog (contract, editable) {
      this.contractAddOnMenu = true
      this.contractAddOnEditable = editable
      this.selectedContract = contract
      this.loadContractClaims()
    },
    closeContractAddOnDialog () {
      this.contractAddOnMenu = false
      this.contractAddOnEditable = false
      this.selectedContract = null
      this.selectedContractAddOns = null
    },
    async loadContractClaims () {
      this.contractAddOnLoading = true
      var resp = await tenantService.getContractClaims(this.selectedContract.id)
      this.selectedContractAddOns = resp
      this.contractAddOnLoading = false
    },
    async saveContractAddOns () {
      this.contractAddOnSaveLoading = true
      var resp = await tenantService.saveContractAddOns({ contractId: this.selectedContract.id, addOns: this.selectedContractAddOns.addOns })
      this.contractAddOnSaveLoading = false
      this.$store.commit('setGlobalSnackbar', {
        message: `${this.selectedContract.contractNumber} add-ons have been saved!`,
        color: 'success'
      })
      this.closeContractAddOnDialog()
      this.loadPlatformContracts()
    },
    async changeDefaultTextVersionCreative () {
      var resp = await tenantService.optInDefaultTextVersionCreative(this.tenant)
    },
    doNothing () {},
    toggleAddOn (key, val) {
      if (!this.selectedContractAddOns) return this.selectedContractAddOns
      if (this.selectedContractAddOns.addOns.some(
        claim => claim.claimType === key && claim.claimValue === val
      )) {
        this.selectedContractAddOns.addOns = this.selectedContractAddOns.addOns.filter(claim => !(claim.claimType === key && claim.claimValue === val))
      } else {
        this.selectedContractAddOns.addOns.push({
          contractId: this.selectedContract.id,
          claimType: key,
          claimValue: val
        })
      }
    },
    contractHasClaim (key, val) {
      if (!this.selectedContractAddOns) return this.selectedContractAddOns
      return this.selectedContractAddOns.packageBaseClaims.some(
        claim => claim.type === key && claim.value === val
      )
    },
    contractHasClaimAddOn (key, val) {
      if (!this.selectedContractAddOns) return this.selectedContractAddOns
      return this.selectedContractAddOns.addOns.some(
        claim => claim.claimType === key && claim.claimValue === val
      )
    },
    contractRowColor (item) {
      if (item.isActive) return 'highlighted'
      return ''
    },
    toggleExpandRow (value) {
      if (this.contractsExpanded.includes(value)) {
        value._expandedIcon = 'down'
        this.contractsExpanded.splice(this.contractsExpanded.indexOf(value), 1)
      } else {
        value._expandedIcon = 'up'
        this.contractsExpanded.push(value)
      }
    }
  }
}
</script>

<style lang="scss">
.highlighted {
  background-color: var(--v-success-lighten5); // background-color: #e2f3ef;
}
</style>
